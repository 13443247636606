import ApiService from "@/core/services/api.service";

var Customer = {
    list: function(searchData) {
        return ApiService.post("/customer/list", searchData);
    },
    save: function(customer) {
        return ApiService.post("/customer/save", customer);
    },
    del: function(id) {
        return ApiService.get("/customer/del?id=" + id);
    },
    findById: function(id) {
        return ApiService.get("/customer/findById?id=" + id);
    }
}

export default Customer;