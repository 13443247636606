<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <b-container fluid style="padding-right: 12.5px; padding-left: 12.5px">
        <b-row class="my-1">
          
          <b-col sm="3">
            <b-row class="my-1">
              <label>客户单位名称查询:</label>
            </b-row>
            <b-row class="my-1">
              <b-form-input
                v-model="search.name"
                placeholder="请输入客户单位名称"
                @change="customer2page()"
              ></b-form-input>
            </b-row>
          </b-col>

          <b-col sm="3" >
            <b-row class="my-1">
              <label style="color: white">.</label>
            </b-row>
            <b-row class="my-1">
              <b-button
                variant="primary"
                @click="customer2page()"
                style="margin-right: 10px"
                >搜 索</b-button
              >
              <b-button variant="success" @click.prevent="customer2initAdd()"
                >新增客户单位</b-button
              >
            </b-row>
          </b-col>

        </b-row>
      </b-container>
    </div>

    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-vertical-center">
          <thead>
            <tr>
              <th class="pl-0 text-center" style="width: 50px">#</th>
              <th class="pl-0" style="min-width: 100px">客户单位名称</th>
              <th class="pl-0" style="min-width: 100px">添加日期</th>
              <th class="pl-0" style="min-width: 100px">状态</th>
              <th class="pl-0" style="min-width: 50px">操作</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in customerList">
              <tr v-bind:key="i">
                <td class="pl-0 text-center">
                  {{ i + 1 }}
                </td>

                <!-- 客户单位名称    -->
                <td class="pl-0">
                  {{ item.name }}
                </td>

                <!-- 添加日期   -->
                <td class="pl-0">
                  {{ item.createdDateDayOfWeek }}<br/>
                  {{ item.createdDateMsg }}
                </td>

                <!-- 状态 -->
                <td class="pl-0">
                  <span
                    class="label label-lg label-inline"
                    v-bind:class="`label-light-${item.active ? 'success' : 'danger'}`"
                  >
                    {{ item.active ? '已启用' : '已禁用' }}
                  </span>
                </td>

                <!-- 操作 -->
                <td class="pl-0">
                  <!-- 设置启用禁用 -->
                  <a class="btn btn-icon btn-light btn-sm" title="启用禁用">
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        title="启用禁用"
                        @click.prevent="customer2changeActive(item.id, !item.active)"
                        src="media/svg/icons/General/Settings-1.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <!-- 编辑 -->
                  <a
                    class="btn btn-icon btn-light btn-sm mx-3"
                    title="修改"
                    @click.prevent="customer2initEdit(item.id)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        title="修改"
                        src="media/svg/icons/Communication/Write.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <!-- 删除 -->
                  <a
                    class="btn btn-icon btn-light btn-sm"
                    title="删除"
                    @click.prevent="customer2del(item.id)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        title="删除"
                        src="media/svg/icons/General/Trash.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <!-- 翻页按钮 -->
        <div class="mt-3">
          <b-pagination
            limit="7"
            first-number
            last-number
            size="lg"
            align="right"
            v-model="search.pageNum"
            :per-page="search.pageSize"
            :total-rows="rows"
            @change="paginationChange"
          ></b-pagination>
        </div>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->

    <LcxCustomerAdd ref="customerAdd" @customer2page="customer2page"></LcxCustomerAdd>
  </div>
</template>

<style lang="scss">
.my-1 {
  padding-right: 10px;
}
.mtop-50 {
  margin-top: -50px !important;
}
</style>

<script>
let that;
import Swal from "sweetalert2";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

import Customer from "@/assets/js/pages/vegetable/customer.js";
import LcxCustomerAdd from "@/view/pages/vegetable/include/customer-add.vue";

export default {
  name: "customer-list",

  data() {
    return {
      search: {
        pageNum: 1,
        // pageSize: 5,
        name: ""
      },
      rows: 100,
      customerList: []
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  components: {
    LcxCustomerAdd
  },
  watch: {
  },
  mounted() {
    that = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "账户管理", route: "" },
      { title: "账户列表" }
    ]);
    this.customer2page();
  },
  methods: {
    paginationChange: function(v) {
      this.search.pageNum = v;
      that.customer2page();
    },
    customer2page: function () {
      Customer.list(this.search).then(({ data }) => {
        that.customerList = data.list;
        that.search.pageSize = data.pageSize;
        that.search.pageNum = data.pageNum;
        that.rows = data.total;
      });
    },
    customer2initAdd: function () {
      this.$refs.customerAdd.customer2initAdd();
    },
    customer2initEdit: function (id) {
      this.$refs.customerAdd.customer2initEdit(id);
    },
    customer2del: function (id) {
      Swal.fire({
        title:
          '<span style="color: black;">删除操作不可恢复，是否确定删除？<span>',
        showCancelButton: true,
        confirmButtonText: `确定`,
        cancelButtonText: `取消`
      }).then(result => {
        if (result.isConfirmed) {
          Customer.del(id).then(({ msg }) => {
            // 重新加载页面列表
            this.customer2page();
            // 消息弹框
            window.Toast("success", msg);
          });
        }
      });
    },
    customer2changeActive: function(id, active) {
      Customer.save({
        id,
        active
      }).then(({ msg }) => {
        // 重新加载页面列表
        this.customer2page();
        // 消息弹框
        window.Toast("success", msg);
      });
    }
  }
};
</script>
