<template>
    <v-app>
        <v-row justify="center">
        <v-dialog
            v-model="customerDialog.dialog"
            persistent
            scrollable
            max-width="600px"
        >
            <v-form
            ref="form"
            lazy-validation
            >
            <v-card>
                <!-- 表单头部 -->
                <v-toolbar dark color="primary" v-lcx-drag>
                <v-toolbar-title>{{ customerDialog.title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="customerDialog.dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-toolbar>

                <v-card-text>
                <v-container style="padding-top: 10px">
                  <v-row>
                    <!-- 客户单位  -->
                    <v-col cols="12" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >客户单位名称 *</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="currCustomer.name"
                            autocomplete="off"
                            :rules="[LcxRules.required]"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>                  
                  </v-row>
                </v-container>
                </v-card-text>

                <!-- start:按钮区 -->
                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn class="ma-2" color="primary" @click.prevent="customer2save()"
                  v-if="customerDialog.isAdd"> 保存 </v-btn>

                <v-btn class="ma-2" color="success" @click.prevent="customer2saveAndClose()"> 保存并关闭 </v-btn>

                <v-btn
                    color="blue-grey"
                    class="ma-2 white--text"
                    @click="closeDialog()"
                >
                    关闭
                </v-btn>
                </v-card-actions>
                <!-- end:按钮区 -->
            </v-card>
            </v-form>
        </v-dialog>
        </v-row>
    </v-app>
</template>

<style lang="scss">
</style>

<script>
let that;
import Customer from "@/assets/js/pages/vegetable/customer.js";
import LcxRules from "@/assets/js/pages/vegetable/lcx-rule.js";

export default {
  name: "LcxCustomerAdd",
  data() {
    return {
      currCustomer: {
        id: null,
        name: null
      },
      customerDialog: {
        title: "新增客户单位", 
        addCount: 0,
        dialog: false,
        isAdd: false
      },
      LcxRules
    };
  },
  mounted() {
      that = this;
  },
  methods: {
    resetCustomer: function () {
      that.currCustomer = {
        id: null,
        name: null
      };
      that.customerDialog = {
        title: "新增客户单位", 
        addCount: 0,
        dialog: false,
        isAdd: false
      };
      if (this.$refs.form && this.$refs.form.resetValidation) {
        this.$refs.form.resetValidation();
      }
    },
    resetCustomerAfterSave: function () {
      that.currCustomer.id = null;
      that.currCustomer.name = null;

      if (this.$refs.form && this.$refs.form.resetValidation) {
        this.$refs.form.resetValidation();
      }
      
      that.customerDialog.addCount++;
      that.customerDialog.title = "已新增客户单位（" + that.customerDialog.addCount + "条）";
    },
    customer2save: function () {
      if(!that.$refs.form.validate()){
        return false;
      }
      Customer.save(that.currCustomer).then(({ msg }) => {
        // 消息弹框
        window.Toast("success", msg);
        that.resetCustomerAfterSave();
      });
    },
    closeDialog: function() {
      that.customerDialog.dialog = false;
      if (that.customerDialog.addCount > 0) {
        that.$emit('customer2page');
      }
    },
    customer2saveAndClose: function () {
      if(!that.$refs.form.validate()){
        return false;
      }
      Customer.save(that.currCustomer).then(({ msg }) => {
        // 关闭模式窗口
        that.customerDialog.dialog = false;
        // 重新加载页面列表
        that.$emit('customer2page');
        // 消息弹框
        window.Toast("success", msg);
      });
    },
    customer2initAdd: function () {
      that.resetCustomer();

      // 模式窗口 - 新增初始化
      that.customerDialog.dialog = true;
      that.customerDialog.title = "新增客户单位";
      that.customerDialog.isAdd = true;
    },
    customer2initEdit: function (id) {
      that.resetCustomer();

      Customer.findById(id).then(({ data }) => {
        // 模式窗口 - 修改初始化
        that.currCustomer = data;
        that.customerDialog.dialog = true;
        that.customerDialog.title = "修改客户单位";
        that.customerDialog.isAdd = false;
      });
    },
  }
};
</script>