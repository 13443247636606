
var LcxRules = {
    id: (v) => {
        const pattern = /^\d+$/
        return pattern.test(v) || '必选项.'
    },
    number: v => { // 整数（正数 负数 0）
        const pattern = /^-?\d+$/
        return pattern.test(v) || '必须是整数.'
    },
    number_6: v => { 
        const pattern = /^\d{6}$/
        return pattern.test(v) || '必须是6位数字.'
    },
    number_7: v => { 
        const pattern = /^\d{7}$/
        return pattern.test(v) || '必须是7位数字.'
    },
    float: v => { 
        const pattern = /^-?\d+(\.\d+)?$/
        return pattern.test(v) || '必须是数字.'
    },
    positiveNumber: v => { // 正整数
        const pattern = /^\d+$/
        return pattern.test(v) || '必须是数字.'
    },
    selected: v => !!v || '必选项.',
    required: v => !!v || '必填项.',
    len_6_20: v => (v.length >= 6 && v.length <= 20) || "长度至少6位，最多20位"
};

export default LcxRules;